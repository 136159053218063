<template>
  <div>
    <b-row>
      <b-col class="search-col">
        <b-form-input
          title="Search"
          id="search"
          :placeholder="translations.tcSearch"
          v-model="searchTerm"
          @change="handleSearchTermChange"
          size="lg"
          required
        >
        </b-form-input>
        <b-button variant="primary" size="lg" class="btn btn-primary text-uppercase" @click="handleSearchClick">
          {{ searchLabelText }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  mixins: [translationMixin],
  data() {
    return {
      searchType: 'Search', // will be 'Search' or 'Clear', English only
      searchLabel: 'Search', // This is the text displayed on the Button
      searchTerm: '',
      translations: {},
    }
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    searchLabelText() {
      if (this.searchType === 'Search') {
        return this.translations.tcSearch
      } else {
        return this.translations.tcClear
      }
    },
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    handleSearchTermChange() {
      this.$emit('searchTermChanged', { term: this.searchTerm, label: this.searchType })
    },
    handleSearchClick() {
      if (this.searchTerm.trim() === '') {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk || 'Ok',
          showCancelButton: false,
          cancelButtonColor: '#d33',
          text: this.translations.tcSearchRequires,
        })
      } else {
        if (this.searchType === 'Search') {
          this.searchType = 'Clear'
        } else {
          this.searchType = 'Search'
          this.searchTerm = ''
        }
        this.$emit('handleSearchClick', this.searchTerm)
      }
    },
  },
  async created() {
    await this.getComponentTranslations('search-input')
    .then(response => {
      this.$set(this, 'translations', response['search-input'])
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.search-col {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  .btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
